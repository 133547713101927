import {
    DELETE_TALENT_MULTIPLE_SUCCESS,
    GET_TALENT_SUCCESS,
    GET_TALENTS_SUCCESS,
    PRESENTATION_TALENT_SUCCESS,
    SALARY_DETAILS_SUCCESS,
    SALARY_TAX_DETAILS_SUCCESS,
    SET_TALENT_STATE,
    UPDATE_PROFILE_STATUS_SUCCESS,
    UPDATE_SEMI_PROFILE_SUCCESS,
    UPDATE_TALENT_MULTIPLE_LEVEL_SUCCESS,
    UPDATE_TALENT_MULTIPLE_SUCCESS,
    UPDATE_TALENT_SUCCESS,
    REFETCH_TALENT,
    UPDATE_EDU_EXP_CERT_SUCCESS,
    SKILL_LANG_HOBBY,
    UPDATE_SKILL_LANG_HOBBY_SUCCESS,
    GET_TALENTS_COUNT_SUCCESS,
    GET_TALENTS_COUNT,
    GET_TALENTS_ES_SUCCESS,
    GET_TALENTS_ES_RESET,
    GET_TIMESHEETS_SUCCESS,
    UPSERT_TIMESHEETS_SUCCESS,
    GET_EXPENSE_REPORTS_SUCCESS,
    UPSERT_EXPENSE_REPORTS_SUCCESS,
    DELETE_EXPENSE_REPORTS_SUCCESS,
    GET_CONTRACTOR_INVOICE_SUCCESS,
    UPSERT_CONTRACTOR_INVOICE_SUCCESS,
    GET_HOLIDAYS_SUCCESS,
    UPSERT_HOLIDAYS_SUCCESS,
    GET_BONUS_SUCCESS, UPSERT_BONUS_SUCCESS, UPSERT_SURVEY_SUCCESS,
    GET_TALENTS_ES_RESET_PAGE,
    GET_CONTRACTOR_INVOICE_EXPLAIN_SUCCESS,
    UPSERT_CONTRACTOR_INVOICE_VALIDATION_ERROR,
    STORE_TALENT_RATING,
    UPDATE_TALENT_BANKING_SUCCESS,
    GET_TALENT_EMPLOYMENT_SUCCESS
} from '../action-types'

const INIT_STATE = {
    talent: null,
    presentation: "",
    semi_profile: {},
    reupdate_talent: 0,
    salary_details: [],
    salary_tax_details: [],
    talents: [],
    talents_es: [],
    ratings: [],
    talents_es_total: 0,
    paginator: {
        currentPage: 1,
        lastPage: 1,
        total: 0
    },
    paginatorCount: {
        currentPage: 1,
        lastPage: 1,
        total: 0
    },
    paginatorCountLoading: false,
    changed_skill_lang_hobby: false,
    timesheets: [],
    expense_reports: [],
    contractor_invoices: [],
    contractor_invoice_single: {
        items: "[]"
    },
    holidays: [],
    bonus: [],
    survey: null,
    page_reset: 0,
    invoice_explain : [],
    validation_error_date : '',
    talent_banking: [],
    talent_employment: []
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TALENT_SUCCESS: {
            return {...state, talent: action?.data?.talentById}
        }

        case GET_TALENTS_SUCCESS: {
            return {...state, talents: action?.data?.talents, paginator: action?.data?.talents?.paginatorInfo, paginatorCount: action?.data?.talents?.paginatorInfo}
        }

        case GET_TALENTS_ES_SUCCESS: {
            return {...state, talents_es: action?.data, paginator: action?.paginator , paginatorCount: action?.paginatorCount, talents_es_total: action?.talents_es_total}
        }

        case GET_TALENTS_ES_RESET: {
            return {...state, talents_es: [], paginator: {
                    currentPage: 1,
                    lastPage: 1,
                    total: 0
                } , paginatorCount: {
                    currentPage: 1,
                    lastPage: 1,
                    total: 0
                }, talents_es_total: 0}
        }

        case STORE_TALENT_RATING: {
            return {...state, ratings: action?.data}
        }

        case GET_TALENTS_ES_RESET_PAGE: {
            return {...state, page_reset: state.page_reset + 1, talents_es_total: 0}
        }

        case GET_TALENTS_COUNT: {
            return {...state, paginatorCountLoading: true}
        }

        case GET_TALENTS_COUNT_SUCCESS: {
            return {...state, paginatorCount: action?.data?.talents?.paginatorInfo, paginatorCountLoading: false}
        }

        case UPDATE_TALENT_SUCCESS: {
            return {...state, talent: action?.data?.upsertTalent || state.talent}
        }

        case PRESENTATION_TALENT_SUCCESS: {
            return {...state, presentation: action?.data?.presentation || state.presentation}
        }

        case UPDATE_SEMI_PROFILE_SUCCESS: {
            return {...state, semi_profile: action?.data || state.semi_profile}
        }

        case SALARY_DETAILS_SUCCESS: {
            return {...state, salary_details: action?.data || state.salary_details}
        }

        case SALARY_TAX_DETAILS_SUCCESS: {
            return {...state, salary_tax_details: action?.data || state.salary_tax_details}
        }

        case REFETCH_TALENT: {
            return {...state, reupdate_talent: state.reupdate_talent + 1}
        }

        case UPDATE_PROFILE_STATUS_SUCCESS: {
            return {...state, reupdate_talent: state.reupdate_talent + 1}
        }

        case DELETE_TALENT_MULTIPLE_SUCCESS: {
            return {...state, reupdate_talent: state.reupdate_talent + 1}
        }

        case UPDATE_TALENT_MULTIPLE_SUCCESS: {
            return {...state, reupdate_talent: state.reupdate_talent + 1}
        }

        case UPDATE_TALENT_MULTIPLE_LEVEL_SUCCESS: {
            return {...state, reupdate_talent: state.reupdate_talent + 1}
        }

        case UPDATE_EDU_EXP_CERT_SUCCESS: {
            let talentCopy = {...state.talent}
            talentCopy.educations = action?.data?.educations
            talentCopy.experiences = action?.data?.experiences
            talentCopy.certifications = action?.data?.certifications

            return {...state, talent: talentCopy}
        }

        case SKILL_LANG_HOBBY: {
            let talentCopy = {...state.talent}
            if (action?.data?.table === 'skills') {
                let exists = false;
                for (let i = 0; i < talentCopy?.skills?.length; i++) {
                    if (talentCopy.skills[i].name === action?.data?.element?.name) {
                        exists = true;
                    }
                }
                if (!exists) {
                    talentCopy.skills.push(action?.data?.element)
                }
            }
            if (action?.data?.table === 'skills_level') {
                talentCopy.skills[action?.data?.index] = action?.data?.element;
            }
            if (action?.data?.table === 'skills_delete') {
                talentCopy.skills.splice(action?.data?.index, 1);
            }

            /* Languages*/
            if (action?.data?.table === 'hobbies') {
                let exists = false;
                for (let i = 0; i < talentCopy?.hobbies?.length; i++) {
                    if (talentCopy.hobbies[i].name === action?.data?.element?.name) {
                        exists = true;
                    }

                    //do not add empty elements
                    if (!action?.data?.element?.name) {
                        exists = true;
                    }
                }
                if (!exists) {
                    talentCopy.hobbies.push(action?.data?.element)
                }
            }
            if (action?.data?.table === 'hobbies_level') {
                talentCopy.hobbies[action?.data?.index] = action?.data?.element;
            }
            if (action?.data?.table === 'hobbies_delete') {
                talentCopy.hobbies.splice(action?.data?.index, 1);
            }

            /* Languages*/
            if (action?.data?.table === 'languages') {
                let exists = false;
                for (let i = 0; i < talentCopy?.languages?.length; i++) {
                    if (talentCopy.languages[i].code === action?.data?.element?.code) {
                        exists = true;
                    }

                    //do not add empty elements
                    if (!action?.data?.element?.code) {
                        exists = true;
                    }
                }
                if (!exists) {
                    talentCopy.languages.push(action?.data?.element)
                }
            }
            if (action?.data?.table === 'languages_level') {
                talentCopy.languages[action?.data?.index] = action?.data?.element;
            }
            if (action?.data?.table === 'languages_delete') {
                talentCopy.languages.splice(action?.data?.index, 1);
            }

            return {...state, talent: talentCopy, changed_skill_lang_hobby: true}
        }

        case UPDATE_SKILL_LANG_HOBBY_SUCCESS: {
            let talentCopy = {...state.talent}

            return {...state, talent: talentCopy, changed_skill_lang_hobby: false}
        }

        case SET_TALENT_STATE: {
            let talentCopy = {...state.talent}
            talentCopy[action?.payload?.key] = action?.payload?.value

            return {...state, talent: talentCopy}
        }

        case GET_TIMESHEETS_SUCCESS: {
            return {...state, timesheets: action.data.timesheetByTalentId.data}
        }

        case UPSERT_TIMESHEETS_SUCCESS: {
            return {...state, timesheets: action.data}
        }

        case GET_EXPENSE_REPORTS_SUCCESS: {
            return {...state, expense_reports: action.data.expenseReportByTalentId.data}
        }

        case UPSERT_EXPENSE_REPORTS_SUCCESS: {
            return {...state, expense_reports: action.data}
        }

        case DELETE_EXPENSE_REPORTS_SUCCESS: {
            return {...state, expense_reports: action.data}
        }

        case GET_CONTRACTOR_INVOICE_SUCCESS: {
            return {...state, contractor_invoices: action.data.contractorInvoicesByTalentId.data}
        }

        case UPSERT_CONTRACTOR_INVOICE_SUCCESS: {
            return {...state, contractor_invoice_single: action.data}
        }

        case GET_HOLIDAYS_SUCCESS: {
            return {...state, holidays: action.data.talentHolidaysByTalentId.data}
        }

        case UPSERT_HOLIDAYS_SUCCESS: {
            return {...state, holidays: action.data}
        }

        case GET_BONUS_SUCCESS: {
            return {...state, bonus: action.data.talentBonusByTalentId.data}
        }

        case UPSERT_BONUS_SUCCESS: {
            return {...state, bonus: action.data}
        }

        case UPSERT_SURVEY_SUCCESS: {
            return {...state, survey: action.data}
        }

        case GET_CONTRACTOR_INVOICE_EXPLAIN_SUCCESS: {
            return {...state, invoice_explain: action.data}
        }

        case UPSERT_CONTRACTOR_INVOICE_VALIDATION_ERROR: {
            return {...state, validation_error_date: action.data.date}
        }

        case UPDATE_TALENT_BANKING_SUCCESS: {
            return {...state, talent_banking: action.data.banking}
        }

        case GET_TALENT_EMPLOYMENT_SUCCESS: {
            return {...state, talent_employment: action.data}
        }

        default:
            return state
    }
}
