import React, {useEffect, useState} from 'react'
import {injectIntl} from 'react-intl'
import platformConfig from '../../config/config'

const PageTitle = ({intl, title}) => {

    const {name} = platformConfig;

    return (
        <div className="flex space-x-4 justify-center items-center mt-8 mb-8">
            <h1 className="py-3 px-6 rounded-full shadow-xxl font-semibold text-secondary text-xl text-center">
                {intl.formatMessage({id: title},{name})}
            </h1>
        </div>
    )
}

export default injectIntl(PageTitle)

