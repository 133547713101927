import React, {lazy, Suspense} from "react";
import {Route, Routes,} from "react-router-dom";

/*Talent Pages*/
import Dashboard from "../views/Talent/Dashboard";
import DashboardCompany from "../views/Company/Dashboard";
import ProfilPersonalData from "../views/Talent/ProfilPersonalData";
import platformConfig from "../config/config";

const PersonalData = lazy(() => import('../views/Talent/PersonalData'))
const Avatar = lazy(() => import('../views/Talent/Avatar'))
const Profil = lazy(() => import('../views/Talent/MyProfil'))
const Express = lazy(() => import('../views/Talent/Express'))
const Semi = lazy(() => import('../views/Talent/Semi'))
const SemiAuto = lazy(() => import('../views/Talent/Semiauto'))
const Mdp = lazy(() => import('../views/Talent/Mdp'))
const Social = lazy(() => import('../views/Talent/Social'))
const Education = lazy(() => import('../views/Talent/Education'))
const Experience = lazy(() => import('../views/Talent/Experience'))
const Certification = lazy(() => import('../views/Talent/Certification'))
const Language = lazy(() => import('../views/Talent/Languages'))
const Hobby = lazy(() => import('../views/Talent/Hobby'))
const Preferences = lazy(() => import('../views/Talent/Preferences'))
const Presentation = lazy(() => import('../views/Talent/Presentation'))
const Preview = lazy(() => import('../views/Talent/Preview'))
const Documents = lazy(() => import('../views/Talent/Documents'))
const Salary = lazy(() => import('../views/Talent/Salary'))
const TalentEntretien = lazy(() => import('../views/Talent/Entretien'))
const TalentAutoProfile = lazy(() => import('../views/Talent/AutoProfile'))
const TalentCandidature = lazy(() => import('../views/Talent/Candidature'))
const TalentUploadCV = lazy(() => import('../views/Talent/UploadCV'))
const TalentUploadLinkedin = lazy(() => import('../views/Talent/UploadLinkedin'))
const TalentUploadChoose = lazy(() => import('../views/Talent/UploadChoose'))
const TalentNeed = lazy(() => import('../views/Talent/Need'))
const TalentWhatNext = lazy(() => import('../views/Talent/WhatNext'))
const TalentHow = lazy(() => import('../views/Talent/HowWorks'))
const TalentWaiting = lazy(() => import('../views/Talent/Waiting'))
const TalentReferTalent = lazy(() => import('../views/Talent/ReferTalent'))
const TalentReferCompany = lazy(() => import('../views/Talent/ReferCompany'))
const TalentPortageEmployed = lazy(() => import('../views/Talent/PortageEmployed'))
const TalentPortageIndependant = lazy(() => import('../views/Talent/PortageIndependant'))
const TalentTimesheet = lazy(() => import('../views/Talent/Timesheet'))
const TalentPayslip = lazy(() => import('../views/Talent/Payslip'))
const TalentPayslipSingle = lazy(() => import('../views/Talent/Payslip/single'))
const TalentPayslipExplain = lazy(() => import('../views/Talent/Payslip/explain'))
const TalentExpenseReport = lazy(() => import('../views/Talent/ExpenseReport'))
const TalentHoliday = lazy(() => import('../views/Talent/Holiday'))
const TalentBonus = lazy(() => import('../views/Talent/Bonus'))
const TalentContract = lazy(() => import('../views/Talent/Contract'))
const TalentOnboarding = lazy(() => import('../views/Talent/Onboarding'))

/*Search Job Offers*/
const SearchJobOffer = lazy(() => import('../views/Search/JobOffer'))
const SearchJobOfferCompare = lazy(() => import('../views/Search/JobOffer/compare'))
const Candidature = lazy(() => import('../views/Search/JobOffer/candidature'))
const FavoriteJobOffer = lazy(() => import('../views/Search/JobOffer/favorite'))

/*Search Talents*/
const SearchTalent = lazy(() => import('../views/Search/Talent'))
const FavoriteTalent = lazy(() => import('../views/Search/Talent/favorite'))
const SearchTalentCompare = lazy(() => import('../views/Search/Talent/compare'))
const Proposals = lazy(() => import('../views/Search/Talent/proposals'))


/*Company pages*/
const ExpressCompany = lazy(() => import('../views/Company/Express'))
const CompanyPersonalData = lazy(() => import('../views/Company/ProfilPersonalData'))
const CompanyProfil = lazy(() => import('../views/Company/MyProfil'))
const InfoCompany = lazy(() => import('../views/Company/InfoCompany'))
const AboutCompany = lazy(() => import('../views/Company/MyCompany'))
const AvatarCompany = lazy(() => import('../views/Company/Avatar'))
const IconCompany = lazy(() => import('../views/Company/Icon'))
const SocietyCompany = lazy(() => import('../views/Company/Society'))
const ConfidentielCompany = lazy(() => import('../views/Company/Confidentiel'))
const MdpCompany = lazy(() => import('../views/Company/Mdp'))
const ContactCompany = lazy(() => import('../views/Company/Contact'))
const PreferencesCompany = lazy(() => import('../views/Company/Preferences'))
const DocumentCompany = lazy(() => import('../views/Company/Documents'))
const ValueCompany = lazy(() => import('../views/Company/Value'))
const JobOffersCompany = lazy(() => import('../views/Company/JobOffers'))
const JobOfferDetail = lazy(() => import('../views/Company/JobOffers/Detail'))
const JobOfferDetailPreview =  lazy(() => import('../views/Company/JobOffers/Preview'))
const JobOfferFlow = lazy(() => import('../views/Company/JobOffers/Flow'))
const JobOfferAuto = lazy(() => import('../views/Company/JobOffers/Auto'))
const JobOfferAutoFirst = lazy(() => import('../views/Company/JobOffers/Auto_first'))
const JobOfferFiche = lazy(() => import('../views/Company/JobOffers/Fiche'))
const JobOfferMatch = lazy(() => import('../views/Company/JobOffers/Match'))
const CompanyCandidature = lazy(() => import('../views/Company/JobOffers/Candidature'))
const CompanyCandidatureSingle = lazy(() => import('../views/Company/JobOffers/Single'))
const CompanyCollab = lazy(() => import('../views/Company/Collab'))
const GuideTemplate = lazy(() => import('../views/Company/JobOffers/GuideTemplate'))
const CompanyHow = lazy(() => import('../views/Company/HowWorks'))
const CompanyNeed = lazy(() => import('../views/Company/Need'))
const CompanyGroup = lazy(() => import('../views/Company/Groups'))
const CompanyTeam = lazy(() => import('../views/Company/Team'))
const CompanyTimesheet = lazy(() => import('../views/Company/Team/timesheet'))
const CompanyExpenseReport = lazy(() => import('../views/Company/Team/expense_report'))
const CompanyInvoice = lazy(() => import('../views/Company/Invoice'))
const CompanyInvoiceSingle = lazy(() => import('../views/Company/Invoice/single'))
const CompanyInvoiceDetails = lazy(() => import('../views/Company/Invoice/details'))
const CompanyPortageEmployed = lazy(() => import('../views/Company/PortageEmployed'))
const CompanyPortageIndependant = lazy(() => import('../views/Company/PortageIndependant'))
const CompanyReferTalent = lazy(() => import('../views/Company/ReferTalent'))
const CompanyReferCompany = lazy(() => import('../views/Company/ReferCompany'))
const CompanyProposition = lazy(() => import('../views/Company/Contracting/proposition'))
const CompanyHolidays = lazy(() => import('../views/Company/Team/holidays'))
const CompanyBonus = lazy(() => import('../views/Company/Team/bonus'))
const CompanyContract = lazy(() => import('../views/Company/Team/contract'))

const Visio = lazy(() => import('../views/Visio'))

/*Common pages*/
const NotFound = lazy(() => import('../views/NotFound'))
const Forbidden = lazy(() => import('../views/Forbidden'))
const Disconnect = lazy(() => import('../views/Auth/Disconnect'))

const talentRoutes = [
    { path: "talent/dashboard" ,components: <Dashboard />},
    { path: "talent/personal-data" ,components: <PersonalData />},
    { path: "talent/avatar" ,components: <Avatar />},
    { path: "talent/profil" ,components: <Profil />},
    { path: "talent/profil-personal-data" ,components: <ProfilPersonalData />},
    { path: "talent/express" ,components: <Express />},
    { path: "talent/need" ,components: <TalentNeed />},
    { path: "talent/what_next" ,components: <TalentWhatNext />},
    { path: "talent/how_works" ,components: <TalentHow />},
    { path: "talent/waiting" ,components: <TalentWaiting />},
    { path: "talent/semi" ,components: <Semi />},
    { path: "talent/semiauto" ,components: <SemiAuto />},
    { path: "talent/choose_upload" ,components: <TalentUploadChoose />},
    { path: "talent/uploadcv" ,components: <TalentUploadCV />},
    { path: "talent/upload_linkedin" ,components: <TalentUploadLinkedin />},
    { path: "talent/mdp" ,components: <Mdp />},
    { path: "talent/social" ,components: <Social />},
    { path: "talent/education" ,components: <Education />},
    { path: "talent/experience" ,components: <Experience />},
    { path: "talent/certification" ,components: <Certification />},
    { path: "talent/languages" ,components: <Language />},
    { path: "talent/hobby" ,components: <Hobby />},
    { path: "talent/preference", components: <Preferences /> },
    { path: "talent/presentation", components: <Presentation /> },
    { path: "talent/preview", components: <Preview /> },
    { path: "talent/documents", components: <Documents /> },
    { path: "talent/salary", components: <Salary /> },
    { path: "talent/auto-profile", components: <TalentAutoProfile /> },
    { path: "talent/refer_talent", components: <TalentReferTalent /> },
    { path: "talent/refer_entreprise", components: <TalentReferCompany /> },
    { path: "talent/port_salary", components: <TalentPortageEmployed /> },
    { path: "talent/port_independant", components: <TalentPortageIndependant /> },
    { path: "talent/timesheet", components: <TalentTimesheet /> },
    { path: "talent/renumeration", components: <TalentPayslip /> },
    { path: "talent/renumeration/:id", components: <TalentPayslipSingle /> },
    { path: "talent/renumeration/explain/:id", components: <TalentPayslipExplain /> },
    { path: "talent/current_invoice", components: <TalentPayslipExplain /> },
    { path: "talent/expense_report", components: <TalentExpenseReport /> },
    { path: "talent/holiday", components: <TalentHoliday /> },
    { path: "talent/bonus", components: <TalentBonus /> },
    { path: "talent/my_contract", components: <TalentContract /> },
    { path: "talent/onboarding", components: <TalentOnboarding /> },
    { path: "talent/search/job_offers", components: <SearchJobOffer /> },
    { path: "talent/search/job_offers/compare", components: <SearchJobOfferCompare /> },
    { path: "talent/candidature", components: <TalentCandidature /> },
    { path: "talent/interviews", components: <TalentEntretien /> },
    { path: "talent/search/job_offers/favorites", components: <FavoriteJobOffer /> },
]

const companyRoutes = [
    { path: "company/express", components: <ExpressCompany /> },
    { path: "company/dashboard", components: <DashboardCompany /> },
    { path: "company/avatar", components: <AvatarCompany /> },
    { path: "company/profil", components: <CompanyProfil /> },
    { path: "company/info", components: <InfoCompany /> },
    { path: "company/about", components: <AboutCompany /> },
    { path: "/company/profil-personal-data", components: <CompanyPersonalData /> },
    { path: "company/icon", components: <IconCompany /> },
    { path: "company/mdp", components: <MdpCompany /> },
    { path: "company/how_works", components: <CompanyHow /> },
    { path: "company/society", components: <SocietyCompany /> },
    { path: "company/contact", components: <ContactCompany /> },
    { path: "company/confidentiel", components: <ConfidentielCompany /> },
    { path: "company/preferences", components: <PreferencesCompany /> },
    { path: "company/document", components: <DocumentCompany /> },
    { path: "company/values", components: <ValueCompany /> },
    { path: "company/job_offer/list", components: <JobOffersCompany /> },
    { path: "company/job_offer/view/:id", components: <JobOfferDetail /> },
    { path: "company/job_offer/preview/:id", components: <JobOfferDetailPreview /> },
    { path: "company/job_offer/fiche/:id", components: <JobOfferFiche /> },
    { path: "company/job_offer/guide/:id", components: <GuideTemplate /> },
    { path: "company/job_offer/match/:id", components: <JobOfferMatch /> },
    { path: "company/job_offer/auto", components: <JobOfferAuto /> },
    { path: "company/job_offer/auto_first", components: <JobOfferAutoFirst /> },
    { path: "company/job_offer/flow/:id", components: <JobOfferFlow /> },
    { path: "company/job_offer/search/talents/favorites", components: <FavoriteTalent /> },
    { path: "company/job_offer/candidature", components: <CompanyCandidature /> },
    { path: "company/job_offer/candidature/single", components: <CompanyCandidatureSingle /> },
    { path: "company/search/talents", components: <SearchTalent /> },
    { path: "company/search/talents/compare", components: <SearchTalentCompare /> },
    { path: "company/proposals", components: <Proposals /> },
    { path: "company/collab", components: <CompanyCollab /> },
    { path: "company/group", components: <CompanyGroup /> },
    { path: "company/my_team", components: <CompanyTeam /> },
    { path: "company/my_team/timesheet/:id", components: <CompanyTimesheet /> },
    { path: "company/my_team/expense_report/:id", components: <CompanyExpenseReport /> },
    { path: "company/my_team/holidays/:id", components: <CompanyHolidays /> },
    { path: "company/my_team/bonus/:id", components: <CompanyBonus /> },
    { path: "company/my_team/contract/:id", components: <CompanyContract /> },
    { path: "company/invoices", components: <CompanyInvoice /> },
    { path: "company/invoices/:id", components: <CompanyInvoiceSingle /> },
    { path: "company/invoice/details/:id", components: <CompanyInvoiceDetails /> },
    { path: "company/need", components: <CompanyNeed /> },
    { path: "company/port_talent_employee", components: <CompanyPortageEmployed /> },
    { path: "company/port_talent_prestataire", components: <CompanyPortageIndependant /> },
    { path: "company/refer_talent", components: <CompanyReferTalent /> },
    { path: "company/refer_entreprise", components: <CompanyReferCompany /> },
    { path: "company/proposition", components: <CompanyProposition /> },
  ];


  const renderRoutes = (routes,disabledRoutes) =>
    routes.filter(route => !disabledRoutes.includes(route.path))
          .map(({ path, components: Component }, index) => (
      <Route
        key={index}
        path={path}
        element={
            Component
        }
      />
    ));
  


const AppRoutes = () => {
    const {disabledRoutes} = platformConfig;
    
    return (
        <Routes>
            {renderRoutes(talentRoutes,disabledRoutes)}
            {renderRoutes(companyRoutes,disabledRoutes)}
            {
                !disabledRoutes.includes("visio/:id") && <Route path="visio/:id" element={<Visio/>}/>
            }

            <Route path="forbidden" element={<Forbidden/>}/>

            <Route path="disconnect" element={<Disconnect/>}/>

            <Route path="*" element={<NotFound/>}/>
        </Routes>
    )
};

export default AppRoutes